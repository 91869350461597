import { DataGrid, GridColDef, GridRowParams } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import { getFormattedDateTime, getFormattedDuration, SETTINGS } from '../App';
import { CircularProgress, Typography } from "@mui/material";


type ResponseObj = Array<MarsClass>

export type MarsClass = {
  grib_code: string;
  short_name: string;
  long_name:	string;
}

type Row = {
  id: string;
  grib_code: string;
  short_name: string;
  long_name: string;
}

const fetchSessions = (): Promise<ResponseObj> => {
  return fetch(SETTINGS.url.marsclasses)
    .then((res) => res.json());
}

export const sessionsColumnsDefinition: GridColDef[] = [
  {
    field: 'grib_code',
    headerName: 'Grib Code',
    width: 100,
    type: 'number',
    hideable: false,
    cellClassName: "monospaced-text"
  },
  {
    field: 'short_name',
    headerName: 'Grib Short Name',
    width: 100,
    flex: 0,
    hideable: false
  },
  {
    field: 'long_name',
    headerName: 'Grib Long Name',
    minWidth: 200,
    flex: 1,
    hideable: false
  }
];


export default function MarsClasses() {

  const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
  const [loadedData, setLoadedData] = useState<Array<Row> | null>(null);
  const [hasError, setHasError] = useState<string | null>(null);
  const [currentNid, setCurrentNid] = useState<string | null>(null);

  function sessionPopUp(e: GridRowParams<Row>) {
    setCurrentNid(e.row.id);
  }

  const getData = () => {
    setIsDataLoading(true);

    fetchSessions()
      .then((res) => {
        setLoadedData(res.map((item) => {
          // 'services' can be a string OR an object with strings as property '1', property '2' etc
          const marsClassDefinition = item;

          const services = marsClassDefinition ?
            (typeof marsClassDefinition === 'object' ? Object.values(marsClassDefinition) : [marsClassDefinition]).join(", ")
            : ''          
          return {
            id: item['grib_code'],
            grib_code: item['grib_code'],
            short_name: item['short_name'],
            long_name: item['long_name'],
          }
        }))
      })
      .catch((error: any) => {
        setHasError(error.message);
      })
      .finally(() => {
        setIsDataLoading(false);
      })
  }

  useEffect(() => {
    getData();
    setInterval(getData,30*1000);
  }, [])

  return (
    <Box>
      <h2 className='section-header'>Mars Classes</h2>

      <Box sx={{ height: 533, width: '100%' }}>

        {
          isDataLoading && !loadedData &&
          <CircularProgress  sx={{ display: "block", margin: "10px auto"}}/>
        }

        {
          hasError &&
          <Box>
            <Typography color="error">
              Error fetching Mars Classes!
            </Typography>
          </Box>
        }

        {
          !hasError && loadedData &&
        <DataGrid
          initialState={{
            sorting: {
              sortModel: [{ field: 'id', sort: 'desc' }],
            },
          }}
          rows={loadedData}
          columns={sessionsColumnsDefinition}
          rowHeight={42}
          pageSize={10}
          rowsPerPageOptions={[10]}
          disableSelectionOnClick
          experimentalFeatures={{ newEditingApi: true }}
          getRowClassName={(params) => `${params.row.timeClass}`}
        />
        }
      </Box>
    </Box>
  );
}
