import BasicGrid from './comp/grid';

import './App.css';

function App() {
  return (BasicGrid()
  );
}

export default App;

/* settings */

const API_BASE_URL = process.env['REACT_APP_API_BASE_URL'];

export const SETTINGS = {
  'url': {
    'base': API_BASE_URL,
    'marsclasses': `${API_BASE_URL}/mars_classes`,
  }
};

/* utility functions */

/**
 * formats a timestamp into "ShortWeekday DD Month YYYY HH:MM:SS" 
 * unless it's already formatted, in this case remove the string "UTC"
 * this is because Infoboard API mixes format for fields with the same name, depending on the endpoint
 * @param {string} timestamp - a UNIX timestamp OR an already formmatted string 
 * @returns {string} - formatted date-time  
 */

export function getFormattedDateTime(timestamp: string) {
  if (timestamp.length !== 10) {
    return timestamp.replace('UTC', '');
  };
  const st = new Date((Number(timestamp || 0)) * 1000);
  const startTime =
    new Intl.DateTimeFormat('en-GB', {
      weekday: 'short',
    }
    ).format(st) + " " +
    new Intl.DateTimeFormat('en-GB', {
      day: '2-digit',
    }
    ).format(st) + "/" +
    new Intl.DateTimeFormat('en-GB', {
      month: 'short',
    }
    ).format(st) + "/" +
    new Intl.DateTimeFormat('en-GB', {
      year: 'numeric',
    }
    ).format(st) + " " +
    new Intl.DateTimeFormat('en-GB', {
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric'
    }).format(st);
  return startTime;
}

export function ledColor(status: string) {
  status = status.toLowerCase();
  if (status === "ok") {
    return "success"
  } else if (status === "degraded") {
    return "warning"
  } else {
    return "error"
  }
};

export function getFormattedDuration(duration: String) {
  const durationNumber = Number(duration || 0);
  const hours = Math.floor(durationNumber / 60)
  const minutes = durationNumber % 60;
  const formattedDuration = (hours > 0 ? hours + "h" : '') + (minutes > 0 ? " " + minutes + "'" : '')
  return formattedDuration;
}